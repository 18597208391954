<template>
  <auth-layout layoutClass="login-page">
    <div class="login-page__header auth-page__header">
      <img src="@/assets/img/small-logo.svg" alt="Logo" />
      <span>{{ $t("loginHelp") }}</span>
    </div>
    <form
      id="login-form"
      method="post"
      novalidate="true"
      class="auth-page__form"
      @submit="onSubmit"
    >
      <easy-input
        v-model="phoneNumber"
        :label="$t('phoneReg')"
        defaultType="tel"
        :mask="phoneMask"
        :onEnter="onSubmit"
        isPhoneNumber
        :placeholder="phonePlaceholder"
        :errorText="$t('errPhone')"
        :isError="isSubmitted && this.unmaskedPhoneNumber.length < 8"
      />
      <easy-input
        v-model="password"
        :label="$t('password')"
        :onEnter="onSubmit"
        :errorText="$t('errPassword')"
        :isError="isSubmitted && password.length < 6"
        isPassword
        :type="'password'"
        autocomplete="current-password"
      />
      <button
        :class="{ disabled: isNotValid, isLoading: isLoading }"
        class="btnWideRound"
      >
        {{ $t("login") }}
      </button>
    </form>

    <div class="login-page__nav">
      <router-link to="/reset-password">{{ $t("forgotPassword") }}</router-link>
      <router-link to="/sign-up">{{ $t("signUp") }}</router-link>
    </div>
  </auth-layout>
</template>

<script>
import EasyInput from "@/components/common/EasyInput";
import { mapActions, mapGetters } from "vuex";
import { logEventFA, logEventFB } from "@/utils/utils";
import eventsRegister from "@/services/events-register";
import AuthLayout from "@/layouts/AuthLayout.vue";

export default {
  name: "LoginPage",
  components: { EasyInput, AuthLayout },
  data() {
    return {
      phoneNumber: localStorage.getItem("loginPhone") || "",
      password: "",
      isSubmitted: false,
      isLoading: false,
      stars: 0,
      phoneMask: {
        mask: "+PPPPPPPPPPPPPP",
        tokens: {
          P: { pattern: /^[0-9]*\.?[0-9]*$/ },
          B: { pattern: /^([1-9][0-9]{0,1})$/ },
        },
      },
    };
  },
  computed: {
    ...mapGetters("root", ["userIPData"]),
    isNotValid() {
      return this.unmaskedPhoneNumber.length < 8 || this.password.length < 6;
    },
    unmaskedPhoneNumber() {
      return this.phoneNumber.replace(/^(\+)|\D/g, "$1");
    },
    phonePlaceholder() {
      const masks = {
        US: "+1-XXX-XXX-XXXX",
        UA: "+380-XX-XXX-XX-XX",
      };

      return masks[this.userIPData.countryCode];
    },
  },
  methods: {
    ...mapActions("user", ["loginUser"]),
    onChangeStars(val) {
      this.stars = val;
    },
    async onSubmit(e) {
      e.preventDefault();
      if (this.isLoading) return false;
      this.isSubmitted = true;
      if (!this.isNotValid) {
        this.isLoading = true;
        try {
          const loginData = {
            username: this.unmaskedPhoneNumber,
            password: this.password,
          };
          await this.loginUser(loginData);

          logEventFA("login", { method: "phone_number" });
          logEventFB("EVENT_NAME_CONTACT", {
            REGISTRATION_METHOD: "phone_number",
          });
          localStorage.setItem("loginPhone", this.phoneNumber);
          this.$router.push({ name: "Root" });
          this.isLoading = false;
        } catch (error) {
          console.log("err", error);
          this.$root.$emit(eventsRegister.addMessage, {
            type: "error",
            message: this.$t("errLoginPass"),
          });
          this.isLoading = false;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.login-page {
  &__header {
    position: relative;

    img {
      display: block;
      margin-bottom: 12px;
      margin-left: -6px;
      position: relative;
      top: -20px;

      .ios & {
        top: -4px;
      }
    }
    span {
      color: #bfbfbf;
      display: block;
      font-size: 14px;
    }
  }

  &__nav {
    padding-top: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    a {
      color: #696969;
      opacity: 0.75;
      line-height: 1;
      padding-top: 12px;
      margin-bottom: 12px;
      display: block;
      font-size: 16px;
      border-bottom: 1px dashed #696969;
    }
  }
}
</style>
