<template>
  <div
    v-if="textarea"
    :class="{ dark, isError }"
    class="easy-input easy-input--textarea"
    key="input-type"
  >
    <textarea
      class="easy-input__control"
      v-bind="$attrs"
      :placeholder="placeholder"
      :type="inputType"
      @input="$emit('input', $event.target.value)"
      autocomplete="off"
      @keydown.enter="onEnter"
      required
    />
    <hr class="easy-input__line" />
    <label class="easy-input__label">{{ label }} {{ requiredStar }}</label>
    <span v-if="isError" class="easy-input__error" key="input-error">{{
      errorText
    }}</span>
    <span
      v-else-if="description"
      class="easy-input__description"
      key="input-description"
      >{{ description }}</span
    >
  </div>
  <div
    v-else
    class="easy-input"
    :class="{ dark, isError }"
    key="input-type-else"
  >
    <input
      v-if="mask"
      class="easy-input__control"
      v-bind="$attrs"
      :type="inputType"
      @click="onFocus"
      @input="$emit('input', $event.target.value)"
      autocomplete="off"
      v-mask="mask"
      @keydown.enter="onEnter"
      required
    />
    <!-- <input
      v-if="money"
      class="easy-input__control"
      v-bind="$attrs"
      :type="inputType"
      @click="onFocus"
      @input="$emit('input', $event.target.value)"
      autocomplete="off"
      v-money="money"
      @keydown.enter="onEnter"
      required
    /> -->
    <input
      v-else
      class="easy-input__control"
      v-bind="$attrs"
      :type="inputType"
      :placeholder="placeholder"
      @keydown.enter="onEnter"
      @click="onFocus"
      @input="$emit('input', $event.target.value)"
      autocomplete="off"
      required
    />
    <hr class="easy-input__line" />
    <label class="easy-input__label">{{ label }} {{ requiredStar }}</label>
    <span v-if="isError" class="easy-input__error" key="input-error">{{
      errorText
    }}</span>
    <span
      v-else-if="description"
      class="easy-input__description"
      key="input-description"
      >{{ description }}</span
    >

    <div
      v-if="isPassword"
      class="easy-input__action-icon"
      @click="toggleTypePassword"
    >
      <img
        v-if="isPasswordHidden"
        slot="action-icon"
        src="@/assets/img/profile/Eye.svg"
      />
      <img
        v-if="!isPasswordHidden"
        slot="action-icon"
        src="@/assets/img/profile/EyeOff.svg"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
//@ts-ignore
import { mask } from "vue-the-mask";
import { VMoney } from "v-money";

export default Vue.extend({
  name: "SelectCountry",
  inheritAttrs: false,
  directives: { mask, money: VMoney },
  props: {
    label: {
      type: String,
    },
    description: {
      type: String,
      default: "",
    },
    errorText: {
      type: String,
      default: "",
    },
    isError: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    selectOnFocus: {
      type: Boolean,
      default: false,
    },
    isPhoneNumber: {
      type: Boolean,
      default: false,
    },
    textarea: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    mask: {
      type: Object,
    },
    money: {
      type: Object,
    },
    defaultType: {
      type: String,
      default: "text",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    onEnter: {
      type: Function,
      default: () => true,
    },
    isPassword: Boolean,
  },
  data() {
    return {
      placeholderFocus: "",
      isPasswordHidden: true,
      inputType: this.isPassword ? "password" : this.defaultType,
    };
  },
  computed: {
    requiredStar() {
      if (this.isRequired) {
        return "*";
      }
      return "";
    },
  },
  methods: {
    onFocus(event) {
      // if (this.isPhoneNumber && !event.target.value.length)
      //   this.$emit("input", "h");
      if (this.selectOnFocus) {
        event.target.setSelectionRange(0, event.target.value.length);
      }
    },
    toggleTypePassword() {
      this.isPasswordHidden = !this.isPasswordHidden;
      this.inputType = this.isPasswordHidden ? "password" : this.defaultType;
    },
  },
});
</script>

<style>
.easy-input {
  position: relative;
  height: auto;
  min-height: 76px;
  padding: 0;
  padding-top: 2px;
  padding-bottom: 25px;
  box-sizing: border-box;
  background: #fff;
  border: none;
  overflow: visible;
  flex: 1 auto;
}
.easy-input__action-icon {
  height: 60px;
  position: absolute;
  right: 0;
  bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dark.easy-input {
  background: #333333;
}
.easy-input__control {
  box-sizing: border-box;
  width: 100%;
  display: block;
  line-height: 24px;
  padding: 2px 8px 2px 0px;
  height: 30px;
  box-sizing: border-box;
  margin-top: 21px;
  border: none;
  border-radius: 0;
  box-shadow: none;
  border-bottom: 1px solid #f1f1f1;
  font-size: 16px;
  color: #282828;
  outline: none;
  cursor: text;
  background: none;
  resize: none;
  font-family: "Arial";
}
.dark .easy-input__control {
  border-bottom: 1px solid #696969;
  color: #fff;
}
.isError .easy-input__control {
  border-bottom: 1px solid #f95757;
}
.easy-input--textarea .easy-input__control {
  padding: 0;
  height: 54px;
  padding-bottom: 8px;
}
.easy-input__control::-webkit-input-placeholder {
  transition: color 300ms ease;
  color: #bfbfbf;
}
.dark .easy-input__control::-webkit-input-placeholder {
  color: #bfbfbf;
}
.easy-input__control:not(:focus)::-webkit-input-placeholder {
  color: transparent;
}
.easy-input__line {
  content: "";
  display: block;
  position: absolute;
  bottom: 25px;
  left: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 1px;
  border: none;
  background: #efb443;
  font-size: 1px;
  will-change: transform, visibility;
  transition: all 200ms ease-out;
  transform: scaleX(0);
  visibility: hidden;
  z-index: 10;
}
.dark .easy-input__line {
  background: #bfbfbf;
}
.easy-input__control:focus ~ .easy-input__line {
  transform: scaleX(1);
  visibility: visible;
}
.easy-input__label {
  position: absolute;
  top: 26px;
  left: 0px;
  font-size: 16px;
  line-height: 21px;
  color: #696969;
  transform-origin: 0 -150%;
  transition: transform 300ms ease;
  pointer-events: none;
}
.dark .easy-input__label {
  color: #bfbfbf;
}
.isError .easy-input__label {
  color: #f95757;
}
.easy-input__control:focus ~ .easy-input__label,
.easy-input__control:valid ~ .easy-input__label,
.easy-input__control.isValid ~ .easy-input__label {
  transform: scale(0.7) translateY(-14px);
}
.easy-input__description {
  position: absolute;
  top: 58px;
  font-size: 12px;
  line-height: 13px;
  color: #bfbfbf;
  max-width: 100%;
  left: 0;
  right: 0;
}
.easy-input__error {
  position: absolute;
  top: 58px;
  font-size: 12px;
  line-height: 13px;
  color: #f95757;
  max-width: 100%;
  left: 0;
  right: 0;
}
.easy-input__group {
  display: flex;
}
.easy-input__group .easy-input:first-child {
  margin-right: 32px;
}
</style>
